import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useParams } from "react-router-dom";
import { selectCompany } from "../homeSlice";
import { styled } from "styled-components";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import {
  Assets,
  Badge,
  ButtonDS,
  Spacer,
  TextCapitalized,
  triggerToast,
} from "@qivia/ui";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import {
  bankDetailsExportAsync,
  billingsSlice,
  documentDownloadAsync,
  selectBankDetailsExportLink,
  selectBankDetailsExportStatus,
} from "./billingsSlice";
import { useEffect } from "react";

export const AccountTab = (props: {
  onClickIsExternalBankAccountModalVisible: () => void;
  onClickIsSepaDebitModalVisible: () => void;
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const bankDetailsExportStatus = useAppSelector(selectBankDetailsExportStatus);
  const bankDetailsExportLink = useAppSelector(selectBankDetailsExportLink);

  useEffect(() => {
    if (bankDetailsExportStatus === "success") {
      triggerToast(t("accountData.bankDetails.upload.success") || "", "valid");
      dispatch(billingsSlice.actions.resetBankDetailsExportStatus());
    } else if (bankDetailsExportStatus === "failed") {
      triggerToast(t("accountData.bankDetails.upload.failure") || "", "error");
      dispatch(billingsSlice.actions.resetBankDetailsExportStatus());
    }
  }, [bankDetailsExportStatus, dispatch, t]);

  useEffect(() => {
    if (bankDetailsExportLink.presignedUrl && bankDetailsExportLink.fileName) {
      void dispatch(documentDownloadAsync(bankDetailsExportLink));
    }
  }, [bankDetailsExportLink, dispatch]);

  if (params.tab !== "account" || !company) {
    return;
  }

  const hasIbanAndBic: boolean = !!(
    company.iban &&
    company.iban !== "" &&
    company.bic &&
    company.bic !== ""
  );

  const ibanDetails = hasIbanAndBic
    ? t("account.bankDetails.ibanDetails", {
        country: company.iban.substring(0, 2),
        code: company.iban.substring(2, 4),
        bank: company.iban.substring(4, 12),
        account: company.iban.substring(12, 22),
      })
    : "";

  let ibanFormatted = "";
  if (hasIbanAndBic) {
    for (let i = 0; i <= 20; i = i + 4) {
      ibanFormatted += company.iban.substring(i, i + 4) + " ";
    }
  }

  const bankDetailsText = t("account.bankDetails.copy.text", {
    iban: ibanFormatted,
    bic: company.bic,
  });

  // TRUE to test the SEPA debit
  // const isSepaDebitVisible = manager?.role === "OWNER";
  const isSepaDebitCardVisibleByManager = false;

  return (
    <StyledColumn>
      <Spacer y={1} />
      <StyledRow>
        <StyledFrame>
          <Spacer x={2} />
          <StyledColumn>
            <Spacer y={2} />
            <StyledTitle>
              <TextCapitalized>{t(`account.balance.title`)}</TextCapitalized>
            </StyledTitle>
            <Spacer y={1.5} />
            <StyledRow>
              <StyledBalance>
                {company.balance.value ? company.balance.value / 100 : 0}
              </StyledBalance>
              <StyledCurrency>
                &nbsp;{t(`account.balance.currency`).toLocaleUpperCase()}
              </StyledCurrency>
            </StyledRow>
            <Spacer y={1.5} />
            <Badge
              text={
                hasIbanAndBic ? t(`account.verified`) : t(`account.unverified`)
              }
              textColor={
                hasIbanAndBic
                  ? colors["colors/system/success/success_dark"]
                  : colors["colors/system/error/error_dark"]
              }
              backgroundColor={
                hasIbanAndBic
                  ? colors["colors/system/success/success_veryLight"]
                  : colors["colors/system/error/error_veryLight"]
              }
            />
            <Spacer y={2} />
          </StyledColumn>
          <Spacer x={2} />
        </StyledFrame>
        <Spacer x={2} />
        {isSepaDebitCardVisibleByManager && company.externalIban && (
          <StyledFrame>
            <Spacer x={2} />
            <StyledColumn>
              <Spacer y={2} />
              <StyledTitle>
                <TextCapitalized>
                  {t(`account.externalBankAccount.created.title`)}
                </TextCapitalized>
              </StyledTitle>
              <Spacer y={1} />
              <StyledSubTitle>
                <TextCapitalized>
                  {t("account.externalBankAccount.modal.bankName")}
                </TextCapitalized>
              </StyledSubTitle>
              {company.name}
              <Spacer y={0.5} />
              <StyledSubTitle>
                <TextCapitalized>
                  {t("account.externalBankAccount.modal.ibanButton")}
                </TextCapitalized>
              </StyledSubTitle>
              {company.externalIban}
              <Spacer y={1} />
              <StyledRow>
                <ButtonDS
                  sizeButton={"S"}
                  format={"hug"}
                  buttonType={"primary"}
                  text={t(`account.sepaDebit.button`)}
                  onClick={props.onClickIsSepaDebitModalVisible}
                />
              </StyledRow>
              <Spacer y={2} />
            </StyledColumn>
            <Spacer x={2} />
          </StyledFrame>
        )}
        {isSepaDebitCardVisibleByManager && !company.externalIban && (
          <StyledSepaDebitColunm>
            <StyledColumn>
              <Spacer y={1} />
              <StyledTitle>
                <TextCapitalized>
                  {t(`account.externalBankAccount.title`)}
                </TextCapitalized>
              </StyledTitle>
              <Spacer y={1} />
              <TextCapitalized>
                <StyledSepaDebitText>
                  <div>{t(`account.externalBankAccount.text`)}</div>
                </StyledSepaDebitText>
              </TextCapitalized>
              <Spacer y={1} />
              <div>
                <ButtonDS
                  sizeButton={"S"}
                  format={"hug"}
                  buttonType={"primary"}
                  text={t(`account.externalBankAccount.button`)}
                  onClick={props.onClickIsExternalBankAccountModalVisible}
                />
              </div>
            </StyledColumn>
          </StyledSepaDebitColunm>
        )}
      </StyledRow>
      <Spacer y={2} />
      {hasIbanAndBic && (
        <StyledRow>
          <StyledFrame>
            <Spacer x={2} />
            <StyledColumn>
              <Spacer y={2} />
              <StyledTitle>
                <TextCapitalized>
                  {t(`account.bankDetails.title`)}
                </TextCapitalized>
              </StyledTitle>
              <Spacer y={1.5} />
              <StyledSubTitle>
                <StyledRowSpaceBetween>
                  <div>{t(`account.bankDetails.iban.title`)}</div>
                  <div>{t(`account.bankDetails.bic.title`)}</div>
                </StyledRowSpaceBetween>
              </StyledSubTitle>
              <StyledRowSpaceBetween>
                <div>{ibanFormatted}</div>
                <div>{company.bic}</div>
              </StyledRowSpaceBetween>
              <Spacer y={0.25} />
              <StyledSmallText>{ibanDetails}</StyledSmallText>
              <Spacer y={1} />
              <StyledSubTitle>
                <TextCapitalized>
                  {t(`account.bankDetails.holder.title`)}
                </TextCapitalized>
              </StyledSubTitle>
              <div>{t(`account.bankDetails.holder.text.part1`)}</div>
              <div>{t(`account.bankDetails.holder.text.part2`)}</div>
              <div>{t(`account.bankDetails.holder.text.part3`)}</div>
              <Spacer y={1} />
              <StyledSubTitle>
                <TextCapitalized>
                  {t(`account.bankDetails.address.title`)}
                </TextCapitalized>
              </StyledSubTitle>
              <StyledSmallText>
                {t(`account.bankDetails.address.text`)}
              </StyledSmallText>
              <Spacer y={2} />
            </StyledColumn>
            <Spacer x={2} />
          </StyledFrame>
          <Spacer x={2} />
          <StyledColumn>
            <Spacer y={1} />
            <StyledTitle>
              <TextCapitalized>
                {t(`account.bankDetails.download.title`)}
              </TextCapitalized>
            </StyledTitle>
            <Spacer y={1.5} />
            {company.bankDetailsBucketFileName && (
              <StyledRow>
                <ButtonDS
                  sizeButton={"S"}
                  format={"hug"}
                  buttonType={"secondary"}
                  text={t(`account.bankDetails.download.button`)}
                  onClick={() => {
                    void dispatch(
                      bankDetailsExportAsync({
                        bucketFileName: company.bankDetailsBucketFileName ?? "",
                        fileName: "Qivia_RIB.pdf",
                      }),
                    );
                  }}
                />
                <Spacer x={1} />
                <div>
                  <ButtonDS
                    leftIcon={<Assets.Copy />}
                    sizeButton={"S"}
                    format={"fill"}
                    buttonType={"primary"}
                    text={t(`account.bankDetails.copy.button`)}
                    onClick={() => {
                      void navigator.clipboard.writeText(bankDetailsText);
                      triggerToast(
                        t("account.bankDetails.copy.success") || "",
                        "valid",
                      );
                    }}
                  />
                </div>
              </StyledRow>
            )}
          </StyledColumn>
        </StyledRow>
      )}
    </StyledColumn>
  );
};

const StyledFrame = styled.div`
  display: flex;
  width: 100%;
  border: 0.0625rem solid ${colors["colors/borders/cells/cells"]};
  border-radius: 0.5rem;
  max-width: 28rem;
  ${typographies["Paragraph/P2"]};
`;

const StyledSepaDebitColunm = styled.div`
  display: flex;
  max-width: 20.5rem;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledRow = styled.div`
  display: flex;
`;

const StyledRowSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledTitle = styled.div`
  ${typographies["Header/H5"]};
`;

const StyledSubTitle = styled.div`
  ${typographies["Body/XXXS"]}
  color: ${colors["colors/text/darkGrey"]};
`;

const StyledSepaDebitText = styled.div`
  ${typographies["Body/XXS"]}
`;

const StyledSmallText = styled.div`
  font-size: 0.5rem;
  font-weight: 400;
  color: ${colors["colors/text/darkGrey"]};
`;

const StyledBalance = styled.div`
  display: flex;
  font-size: 1.5rem;
  font-weight: 700;
`;

const StyledCurrency = styled.div`
  display: flex;
  ${typographies["Header/H4"]};
`;
