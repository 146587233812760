import {
  triggerToast,
  Assets,
  ButtonDS,
  SelectDS,
  AddressFormDS,
  ModalWithLeftPartDS,
} from "@qivia/ui";
import { useState, useMemo, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectListAllVehiclesInDb } from "../vehicles/vehiclesSlice";
import { BadgesListDisplayed, ModalVehicleType } from "./badgesAPI";
import {
  selectBadgeCreationStatus,
  selectBadgesList,
  createBadgeAsync,
} from "./badgesSlice";
import { selectCompany } from "../homeSlice";
import { AddressInput } from "../companies/companiesApi";

const findVehiclesNotAssignedToBadge = (
  vehicles: ModalVehicleType[],
  badgesList: BadgesListDisplayed[],
) => {
  return vehicles.filter(
    (vehicle) =>
      badgesList.find(
        (badge) =>
          (badge.registrationNumber === vehicle.registrationNumber ||
            badge.reference === vehicle.reference ||
            vehicle.status === "DEACTIVATED") &&
          badge.status !== "IN_OPPOSITION" &&
          badge.status !== "ERROR",
      ) === undefined,
  );
};

export const AddBadgeModal = (props: {
  visible: boolean;
  onCloseModal: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { onCloseModal } = props;

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const vehicles = useAppSelector(selectListAllVehiclesInDb);
  const company = useAppSelector(selectCompany);
  const badgeCreationStatus = useAppSelector(selectBadgeCreationStatus);
  const badgesList: BadgesListDisplayed[] = useAppSelector(selectBadgesList);

  const addressInitValues = useMemo(() => {
    return {
      addressLine1: "",
      addressLine2: "",
      city: "",
      postalCode: "",
      country: "FR",
    };
  }, []);
  const [isValidAddressForm, setIsValidAddressForm] = useState(false);
  const [vehicle, setVehicle] = useState<string>("");
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [addressInput, setAddressInput] =
    useState<AddressInput>(addressInitValues);

  const vehiclesNotAssignedToBadge: ModalVehicleType[] =
    findVehiclesNotAssignedToBadge(vehicles, badgesList);

  const optionVehicle = useMemo(
    () =>
      vehiclesNotAssignedToBadge.map((vehicle) => {
        return {
          value: vehicle.uuid,
          label: [vehicle.registrationNumber, vehicle.reference]
            .filter((d) => d)
            .join(" - "),
        };
      }),
    [vehiclesNotAssignedToBadge],
  );

  const valueOptionVehicle = useMemo(
    () => optionVehicle.find((option) => option.value === vehicle),
    [optionVehicle, vehicle],
  );

  useEffect(() => {
    if (badgeCreationStatus === "success") {
      triggerToast(t("badges.creation.success") || "", "valid");
    } else if (badgeCreationStatus === "failed") {
      triggerToast(t("badges.creation.failure") || "", "error");
    }
  }, [badgeCreationStatus, t]);

  useEffect(() => {
    if (vehicle && isValidAddressForm) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [isValidAddressForm, vehicle]);

  useEffect(() => {
    addressInput.addressLine1 === "" ||
    addressInput.city === "" ||
    addressInput.postalCode === ""
      ? setIsValidAddressForm(false)
      : setIsValidAddressForm(true);
  }, [addressInput]);

  const resetValue = useCallback(() => {
    setAddressInput(addressInitValues);
    setVehicle("");
    setDisplayError(false);
  }, [addressInitValues]);

  const onSubmit = useCallback(() => {
    const vehicleData = vehicles.find((v) => v.uuid === vehicle);
    if (!company || !vehicleData) {
      return;
    }
    const badgeData = {
      request: {
        vehicle: {
          uuid: vehicle,
          registrationNumber: vehicleData.registrationNumber,
          reference: vehicleData.reference,
        },
        deliveryAddress: addressInput,
      },
      companyUuid: company.uuid,
    };
    void dispatch(createBadgeAsync(badgeData));
    resetValue();
    onCloseModal();
  }, [
    addressInput,
    company,
    dispatch,
    onCloseModal,
    resetValue,
    vehicle,
    vehicles,
  ]);

  const updateAddress = useCallback(
    (key: keyof AddressInput, value: string) => {
      const newValueState = {
        ...addressInput,
        [key]: value,
      };
      setAddressInput(newValueState);
    },
    [addressInput],
  );
  const clickToSubmit = useCallback(() => {
    setDisplayError(true);
    if (!buttonDisabled) {
      onSubmit();
    }
  }, [buttonDisabled, onSubmit]);

  return (
    <ModalWithLeftPartDS
      title={t("badges.modal.title") || ""}
      tagText={t("badges.modal.tag") || ""}
      visible={props.visible}
      onClose={() => {
        resetValue();
        props.onCloseModal();
      }}
      leftPart={{
        button: (
          <ButtonDS
            text={t("badges.modal.add") || ""}
            onClick={clickToSubmit}
            disabled={buttonDisabled}
            format="fill"
            buttonType="primary"
          />
        ),
        icon: <Assets.BadgeLogo />,
      }}
    >
      <SelectDS
        label={t("badges.modal.vehicle") || ""}
        placeholder={t("badges.modal.vehicle.placeholder") || ""}
        value={valueOptionVehicle}
        options={optionVehicle}
        onChange={(selectedOption) =>
          setVehicle(selectedOption ? selectedOption.value : "")
        }
        error={
          (displayError &&
            vehicle === "" &&
            t("badges.modal.error.selectInput")) ||
          undefined
        }
        allWidth
      />
      <AddressFormDS
        label={t("address.label.delivery") || ""}
        addressForm={addressInput}
        error={displayError}
        onChange={(key, value) => updateAddress(key, value)}
        onKeyDown={(key) => key === "Enter" && clickToSubmit()}
      />
    </ModalWithLeftPartDS>
  );
};
