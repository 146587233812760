import {
  TextCapitalized,
  Spacer,
  InputDS,
  ButtonDS,
  hasIbanFormat,
} from "@qivia/ui";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PublicCompany } from "../homeSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { createExternalBankAccountAsync } from "./sepaDebitSlice";

export const ExternalBankAccountModal = (props: {
  onCloseModal: () => void;
  toaster?: JSX.Element;
  company: PublicCompany;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [ibanInput, setIbanInput] = useState<string>("");
  const [displayError, setDisplayError] = useState<boolean>(false);

  const isValidForm: boolean = useMemo(
    () => hasIbanFormat(ibanInput) && ibanInput !== "",
    [ibanInput],
  );

  const getIbanError = useCallback(() => {
    if (!displayError) return undefined;
    if (ibanInput === "") {
      return t("account.externalBankAccount.modal.iban.error.empty");
    } else if (!hasIbanFormat(ibanInput)) {
      return t("account.externalBankAccount.modal.iban.error.valid");
    } else return undefined;
  }, [displayError, ibanInput, t]);

  const onSubmit = useCallback(
    (iban: string) => {
      void dispatch(
        createExternalBankAccountAsync({
          companyUuid: props.company.uuid,
          externalIban: iban,
        }),
      );
      props.onCloseModal();
    },
    [dispatch, props],
  );

  const clickToSubmit = useCallback(() => {
    setDisplayError(true);
    isValidForm && onSubmit(ibanInput);
  }, [ibanInput, isValidForm, onSubmit]);

  return (
    <StyledModal>
      {props.toaster}
      <StyledContainer>
        <StyledForm>
          <Spacer y={2} />
          <StyledColumn>
            <StyledExternal>
              <StyledRow>
                <Spacer x={1} />
                <StyledColumn>
                  <Spacer y={1} />
                  <TextCapitalized>
                    {t("account.externalBankAccount.modal.externalBankAccount")}
                  </TextCapitalized>
                  <Spacer y={2} />
                  <StyledDescription>
                    <TextCapitalized>
                      {t(
                        "account.externalBankAccount.modal.externalBankAccount.description",
                      )}
                    </TextCapitalized>
                  </StyledDescription>
                  <Spacer y={2} />
                </StyledColumn>
                <Spacer x={1} />
              </StyledRow>
            </StyledExternal>
            <Spacer y={1} />
            <TextCapitalized>
              {t("account.externalBankAccount.modal.bankName")}
            </TextCapitalized>
            <Spacer y={0.25} />
            <InputDS
              value={props.company.name}
              withoutSpacerBottom
              height={2.18}
              isDisabled={true}
            />
            <Spacer y={1} />
            <TextCapitalized>
              {t("account.externalBankAccount.modal.ibanButton")}
            </TextCapitalized>
            <Spacer y={0.25} />
            <InputDS
              placeholder={t("account.externalBankAccount.modal.placeholder")}
              error={getIbanError()}
              value={ibanInput}
              onChange={(e) => setIbanInput(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
              withoutSpacerBottom
              height={2.18}
              isPlaceholderUppercase
            />
            <Spacer y={2} />
            <ButtonDS
              sizeButton={"S"}
              format={"hug"}
              buttonType={"primary"}
              text={t("account.externalBankAccount.modal.confirmButton")}
              onClick={() => clickToSubmit()}
            />
          </StyledColumn>
          <Spacer x={2} />
        </StyledForm>
      </StyledContainer>
    </StyledModal>
  );
};

const StyledRow = styled.div`
  display: flex;
`;

const StyledModal = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500%;
  overflow: auto;
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500%;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30.5rem;
`;

const StyledExternal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors["colors/borders/cells/cells"]};
  border-radius: 0.5rem;
  max-width: 40rem;
  ${typographies["Paragraph/P2"]};
`;

const StyledDescription = styled.div`
  ${typographies["Body/XXS"]};
`;
