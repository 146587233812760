import { TextCapitalized, Spacer, ButtonDS, CheckBox } from "@qivia/ui";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PublicCompany, PublicManager } from "../homeSlice";

export const SepaDebitModal = (props: {
  onCloseModal: () => void;
  toaster?: JSX.Element;
  company: PublicCompany;
  manager: PublicManager;
}) => {
  const { t } = useTranslation();
  const [isSepaDebitAccepted, setIsSepaDebitAccepted] =
    useState<boolean>(false);

  const onSubmit = useCallback(() => {
    console.log("SEPA DEBIT signe");
    props.onCloseModal();
  }, [props]);

  const clickToSubmit = useCallback(() => {
    isSepaDebitAccepted && onSubmit();
  }, [isSepaDebitAccepted, onSubmit]);

  return (
    <StyledModal>
      {props.toaster}
      <StyledContainer>
        <StyledForm>
          <Spacer y={2} />
          <StyledColumn>
            <StyledConditionsCard>
              <StyledRow>
                <Spacer x={1.5} />
                <StyledColumn>
                  <Spacer y={1.5} />
                  <StyledDescription>
                    <TextCapitalized>
                      {t("account.sepaDebit.modal.description")}
                    </TextCapitalized>
                  </StyledDescription>
                  <Spacer y={1.5} />
                </StyledColumn>
                <Spacer x={1.5} />
              </StyledRow>
            </StyledConditionsCard>
            <StyledManagerCard>
              <Spacer y={1.5} />
              <StyledRow>
                <StyledManagerRow>
                  <Spacer x={1.5} />
                  <TextCapitalized>
                    <StyledManagerTitle>
                      {t("account.sepaDebit.modal.manager.firstName")}
                    </StyledManagerTitle>
                    <Spacer y={0.5} />
                    <StyledManagerSubTitle>
                      {props.manager.firstName}
                    </StyledManagerSubTitle>
                  </TextCapitalized>
                </StyledManagerRow>
                <StyledManagerRow>
                  <TextCapitalized>
                    <StyledManagerTitle>
                      {t("account.sepaDebit.modal.manager.lastName")}
                    </StyledManagerTitle>
                    <Spacer y={0.5} />
                    <StyledManagerSubTitle>
                      {props.manager.lastName}
                    </StyledManagerSubTitle>
                  </TextCapitalized>
                  <Spacer x={1.5} />
                </StyledManagerRow>
              </StyledRow>
              <Spacer y={1.5} />
              <StyledRow>
                <Spacer x={1.5} />
                <TextCapitalized>
                  <StyledManagerTitle>
                    {t("account.sepaDebit.modal.manager.email")}
                  </StyledManagerTitle>
                  <Spacer y={0.5} />
                  <StyledManagerSubTitle>
                    {props.manager.email}
                  </StyledManagerSubTitle>
                </TextCapitalized>
                <Spacer x={1.5} />
              </StyledRow>
              <Spacer y={1.5} />
            </StyledManagerCard>

            <StyledCompanyCard>
              <Spacer y={1.5} />
              <StyledRow>
                <Spacer x={1.5} />
                <TextCapitalized>
                  <StyledCompanyTitle>
                    {" "}
                    {t("account.sepaDebit.modal.company.name")}
                  </StyledCompanyTitle>
                  <Spacer y={0.5} />
                  <StyledCompanySubTitle>
                    {props.company?.name}
                  </StyledCompanySubTitle>
                </TextCapitalized>
                <Spacer x={1.5} />
              </StyledRow>
              <Spacer y={1.5} />
              <StyledRow>
                <Spacer x={1.5} />
                <TextCapitalized>
                  <StyledCompanyTitle>
                    {t("account.sepaDebit.modal.externalBankAccount.iban")}
                  </StyledCompanyTitle>
                  <Spacer y={0.5} />
                  <StyledCompanySubTitle>
                    {props.company?.externalIban}
                  </StyledCompanySubTitle>
                </TextCapitalized>
                <Spacer x={1.5} />
              </StyledRow>
              <Spacer y={1.5} />
            </StyledCompanyCard>
            <Spacer y={1.5} />
            <StyledRow>
              <Spacer x={1.5} />
              <CheckBox
                text={t("account.sepaDebit.modal.accepted")}
                onClick={() => setIsSepaDebitAccepted(!isSepaDebitAccepted)}
                state={isSepaDebitAccepted ? "CHECKED" : "UNCHECKED"}
              />
            </StyledRow>
            <Spacer y={1.5} />
            <ButtonDS
              sizeButton={"S"}
              format={"hug"}
              buttonType={"primary"}
              text={t("account.sepaDebit.button.confirm")}
              onClick={() => clickToSubmit()}
              disabled={!isSepaDebitAccepted}
            />
          </StyledColumn>
          <Spacer x={2} />
        </StyledForm>
      </StyledContainer>
    </StyledModal>
  );
};

const StyledRow = styled.div`
  display: flex;
`;

const StyledManagerRow = styled.div`
  display: flex;
  width: 50%;
`;

const StyledModal = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500%;
  overflow: auto;
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500%;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30.5rem;
`;

const StyledConditionsCard = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colors["colors/borders/cells/cells"]};
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  max-width: 40rem;
  ${typographies["Paragraph/P2"]};
`;

const StyledDescription = styled.div`
  ${typographies["Body/XXS"]};
`;

const StyledManagerCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 0.0625rem solid ${colors["colors/borders/cells/cells"]};
  ${typographies["Paragraph/P2"]};
`;

const StyledManagerTitle = styled.div`
  ${typographies["Body/M"]}
  color: ${colors["colors/text/black"]}
`;

const StyledManagerSubTitle = styled.div`
  ${typographies["Body/S"]}
  color: ${colors["colors/text/black"]}
`;

const StyledCompanyCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 0.0625rem solid ${colors["colors/borders/cells/cells"]};
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  ${typographies["Paragraph/P2"]};
`;

const StyledCompanyTitle = styled.div`
  ${typographies["Body/XXS"]}
  color: ${colors["colors/text/black"]}
`;

const StyledCompanySubTitle = styled.div`
  ${typographies["Body/M"]}
  color: ${colors["colors/text/black"]}
`;
