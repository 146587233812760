import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios, QueryStatus } from "../../../utils";
import { RootState } from "../../../redux/store";
import {
  BadgeCreation,
  BadgesList,
  BadgesListApi,
  UpdateBadgeStatusType,
} from "./badgesAPI";

export interface BadgesState {
  badgesListStatus: QueryStatus;
  badgesList: BadgesList[];
  badgeCreationStatus: QueryStatus;
  badgeUpdateStatus: QueryStatus;
}

const initialState: BadgesState = {
  badgesListStatus: "idle",
  badgesList: [],
  badgeCreationStatus: "idle",
  badgeUpdateStatus: "idle",
};

export const badgesListAsync = createAsyncThunk(
  "badgesList/call",
  async (companyUuid: string) => {
    const axios = authAxios();
    const response = await axios.get<Array<BadgesListApi>>(
      `badges/company/${companyUuid}`,
    );
    const badgesList = response.data.map((payload) => ({
      ...payload,
      date: payload.createdAt,
      status: payload.status === "RETURNED" ? "IN_OPPOSITION" : payload.status,
    }));
    return badgesList;
  },
);

export const createBadgeAsync = createAsyncThunk(
  "createBadge/call",
  async (payload: { companyUuid: string; request: BadgeCreation }) => {
    const axios = authAxios();
    await axios.post(`badge/company/${payload.companyUuid}`, payload.request);
  },
);

export const updateBadgeStatusAsync = createAsyncThunk(
  "updateBadgeStatus/call",
  async (payload: UpdateBadgeStatusType) => {
    const axios = authAxios();
    await axios.post(`/badge/${payload.badgeUuid}/update_status`, {
      status: payload.status,
      companyUuid: payload.companyUuid,
    });
  },
);

export const badgesSlice = createSlice({
  name: "badges",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetBadgesListStatus(state) {
      state.badgesListStatus = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(badgesListAsync.pending, (state) => {
        state.badgesListStatus = "processing";
      })
      .addCase(badgesListAsync.fulfilled, (state, action) => {
        state.badgesList = action.payload;
        state.badgeCreationStatus = "idle";
        state.badgeUpdateStatus = "idle";
        state.badgesListStatus = "success";
      })
      .addCase(badgesListAsync.rejected, (state) => {
        state.badgesListStatus = "failed";
      })
      .addCase(createBadgeAsync.pending, (state) => {
        state.badgeCreationStatus = "processing";
      })
      .addCase(createBadgeAsync.fulfilled, (state) => {
        state.badgeCreationStatus = "success";
      })
      .addCase(createBadgeAsync.rejected, (state) => {
        state.badgeCreationStatus = "failed";
      })
      .addCase(updateBadgeStatusAsync.pending, (state) => {
        state.badgeUpdateStatus = "processing";
      })
      .addCase(updateBadgeStatusAsync.fulfilled, (state) => {
        state.badgeUpdateStatus = "success";
      })
      .addCase(updateBadgeStatusAsync.rejected, (state) => {
        state.badgeUpdateStatus = "failed";
      });
  },
});

export const selectBadgesList = (state: RootState) => state.badges.badgesList;

export const selectBadgesListStatus = (state: RootState) =>
  state.badges.badgesListStatus;

export const selectBadgeCreationStatus = (state: RootState) =>
  state.badges.badgeCreationStatus;

export const selectBadgeUpdateStatus = (state: RootState) =>
  state.badges.badgeUpdateStatus;

export default badgesSlice.reducer;
