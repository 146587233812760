import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios, QueryStatus } from "../../../utils";
import { RootState } from "../../../redux/store";
import { CreateExternalBankAccount } from "./sepaDebitApi";

export interface SepaDebitState {
  createExternalBankAccountStatus: QueryStatus;
}

const initialState: SepaDebitState = {
  createExternalBankAccountStatus: "idle",
};

export const createExternalBankAccountAsync = createAsyncThunk(
  "sepa_debit/external_bank_account",
  async (payload: CreateExternalBankAccount) => {
    const axios = authAxios();
    await axios.post(`/sepa_debit/external_bank_account`, payload);
  },
);

export const sepaDebitSlice = createSlice({
  name: "sepaDebit",
  initialState,
  reducers: {
    resetExternalBankAccountStatus(state) {
      state.createExternalBankAccountStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createExternalBankAccountAsync.pending, (state) => {
        state.createExternalBankAccountStatus = "processing";
      })
      .addCase(createExternalBankAccountAsync.fulfilled, (state) => {
        state.createExternalBankAccountStatus = "success";
      })
      .addCase(createExternalBankAccountAsync.rejected, (state) => {
        state.createExternalBankAccountStatus = "failed";
      });
  },
});

export const selectCreateExternalBankAccountStatus = (state: RootState) =>
  state.sepaDebit.createExternalBankAccountStatus;

export default sepaDebitSlice.reducer;
