import {
  ToastContainer,
  SortingDirectionType,
  sortByAlphabeticalOrder,
  SortByAlphabeticalOrderType,
  LaunchPage,
  sortByDateTime,
  PageDS,
  TableDS,
  EmptyTableComponent,
  TableTagBadge,
  AddOutlined,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCompany } from "./../homeSlice";
import {
  badgesListAsync,
  selectBadgeCreationStatus,
  selectBadgesList,
  selectBadgesListStatus,
  selectBadgeUpdateStatus,
} from "./badgesSlice";
import { unreachable } from "../../../utils";
import { BadgesList, BadgesListDisplayed } from "./badgesAPI";
import { BadgeSidePanel } from "./SidePanel";
import { useNavigate } from "react-router-dom";
import { AddBadgeModal } from "./addBadgeModal";
import { listAllVehiclesInDbAsync } from "../vehicles/vehiclesSlice";

export const Badges = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sortingDirection, setSortingDirection] =
    useState<SortingDirectionType>("desc");
  const [listDisplayed, setListDisplayed] = useState<
    BadgesListDisplayed[] | null
  >(null);
  const company = useAppSelector(selectCompany);
  const badgesListInit = useAppSelector(selectBadgesList);
  const badgesListStatus = useAppSelector(selectBadgesListStatus);
  const createBadgeStatus = useAppSelector(selectBadgeCreationStatus);
  const updateBadgeStatus = useAppSelector(selectBadgeUpdateStatus);

  const badgesList: BadgesList[] = useMemo(() => {
    return sortByDateTime(badgesListInit, "desc");
  }, [badgesListInit]);

  const findKeysToTranslate = (key: keyof BadgesList, value: string) => {
    switch (key) {
      case "status":
        return `badges.status.${value}`;
      default:
        return value;
    }
  };

  useEffect(() => {
    if (company) {
      void dispatch(badgesListAsync(company.uuid));
      void dispatch(listAllVehiclesInDbAsync({ companyUuid: company.uuid }));
    }
  }, [dispatch, company, createBadgeStatus, updateBadgeStatus]);

  useEffect(() => {
    if (badgesList && badgesListStatus === "success") {
      const badgesDateTimeSorted = sortByDateTime(badgesList, "desc");
      setListDisplayed(badgesDateTimeSorted);
    }
  }, [badgesList, badgesListStatus]);

  const sortBadgesByAlphabeticalOrder = useCallback(
    (column: string) => {
      if (!listDisplayed) return;
      const badgesAlphabeticalSorted = sortByAlphabeticalOrder(
        listDisplayed,
        sortingDirection,
        column as keyof SortByAlphabeticalOrderType,
      );
      setListDisplayed(badgesAlphabeticalSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [listDisplayed, sortingDirection],
  );

  const sidePanelAction = useCallback(
    (props: Partial<BadgesList>) => {
      navigate(`/home/badges/${props.uuid}`);
    },
    [navigate],
  );

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  const searchBarProps = {
    values: badgesList,
    setFilterValues: setListDisplayed,
    keysToTranslate: ["status" as const],
    findKeysToTranslate,
    keysToIgnore: ["uuid" as const, "date" as const, "vehicleUuid" as const],
  };

  const render =
    (row: BadgesListDisplayed) => (key: keyof BadgesListDisplayed) => {
      switch (key) {
        case "status":
          return <TableTagBadge status={row[key]} />;
        case "number":
        case "registrationNumber":
        case "reference":
          return row[key];
      }
      unreachable(key);
    };

  const headers = {
    number: {
      text: t("badges.column.number"),
    },
    status: {
      text: t("badges.column.status"),
    },
    registrationNumber: {
      text: t("badges.column.registrationNumber"),
      sortAction: () => sortBadgesByAlphabeticalOrder("registrationNumber"), //check dans traznsactions
    },
    reference: {
      text: t("badges.column.reference"),
      sortAction: () => sortBadgesByAlphabeticalOrder("reference"),
    },
  };

  const cta = {
    title: t("badges.cta"),
    action: () => {
      setIsModalVisible(true);
    },
    width: 12,
    leftIcon: <AddOutlined />,
  };

  return (
    <PageDS
      title={t("badges.title")}
      isEmptyTable={listDisplayed.length === 0}
      toaster={<ToastContainer />}
      searchBar={searchBarProps}
      cta={cta}
    >
      <TableDS<keyof BadgesListDisplayed, BadgesListDisplayed>
        data={listDisplayed}
        headers={headers}
        render={render}
        onClickRow={sidePanelAction}
        emptyContent={
          <EmptyTableComponent
            pageName="badges"
            isSearchResult={searchBarProps.values.length > 0}
            cta={cta}
          />
        }
      />
      <BadgeSidePanel />
      <AddBadgeModal
        visible={isModalVisible}
        onCloseModal={() => {
          setIsModalVisible(false);
        }}
      />
    </PageDS>
  );
};
